import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption"

import One from "../../../content/assets/fireplace-accessories/1.jpg"
import Two from "../../../content/assets/fireplace-accessories/2.jpg"
import Three from "../../../content/assets/fireplace-accessories/3.jpg"
import Four from "../../../content/assets/fireplace-accessories/4.jpg"
import Five from "../../../content/assets/fireplace-accessories/5.jpg"
import Six from "../../../content/assets/fireplace-accessories/6.jpg"
import Seven from "../../../content/assets/fireplace-accessories/7.jpg"
import Eight from "../../../content/assets/fireplace-accessories/8.jpg"
import Nine from "../../../content/assets/fireplace-accessories/9.jpg"
import Ten from "../../../content/assets/fireplace-accessories/10.jpg"
import Eleven from "../../../content/assets/fireplace-accessories/11.jpg"

const FireplaceAccessories = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photos = [
    {
      src: One,
      width: 1,
      height: 1.1,
      caption: `Colonial Style Andirons height 18 inches`,
    },
    {
      src: Two,
      width: 1,
      height: 1.7,
      caption: `Elk Antler Fireplace Tool Set # 4. Can be made any size. Hand tied broom.`,
    },
    {
      src: Three,
      width: 1,
      height: 1.5,
      caption: `5 Piece Fireplace Tool Set #1 Height 34 inches`,
    },
    {
      src: Four,
      width: 1,
      height: 1.4,
      caption: `5 Piece Fireplace Tool Set #1 Height 34 inches`,
    },
    {
      src: Five,
      width: 1,
      height: 1.8,
      caption: `5 Piece Fireplace Tool Set #1 Height 34 inches`,
    },
    {
      src: Six,
      width: 1,
      height: 1.7,
      caption: `Fireplace Tool Set # 3. Can be made any size, normally 28 inches to 32 inches tall. Hand tied broom. All welds are forge welded.`,
    },
    {
      src: Seven,
      width: 1,
      height: 1.6,
      caption: `Fireplace Tool Set W/Marbles in Handles`,
    },
    {
      src: Eight,
      width: 1,
      height: 1.7,
      caption: `Fireplace Tool Set W/Marbles in Handles`,
    },
    {
      src: Nine,
      width: 2,
      height: 0.5,
      caption: `38 Inch Long - Fire Log Handling Tongs`,
    },
    {
      src: Ten,
      width: 1.5,
      height: 0.8,
      caption: `38 Inch Long - Fire Log Handling Tongs`,
    },
    {
      src: Eleven,
      width: 1.5,
      height: 0.8,
      caption: `38 Inch Long - Fire Log Handling Tongs`,
    },
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Fireplace Accessories</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default FireplaceAccessories
